<template>
  <PageWrapper :tabActive.sync="selectedTab" :tabs="tabs">
    <PagePanel>
      <component :is="getComponent" :cpsDepositList="cpsDepositList"></component>
    </PagePanel>
  </PageWrapper>
</template>

<script>
import cpsDepositListingMixin from '@/mixins/page/deposit/cpsDepositListing'

export default {
  name: 'FsaDeposit',
  mixins: [cpsDepositListingMixin],
  data() {
    return {
      selectedTab: 'cryptoCurrency',
      tabs: [
        {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/cryptoCurrency/depositList').component,
          label: this.$t('common.withdrawChannel.CRYPTOCURRENCY'),
          name: `cryptoCurrency`,
        },
        {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/creditCard/depositList').component,
          label: this.$t('common.withdrawChannel.creditcard'),
          name: `creditCard`,
        },
        {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/eWallet/depositList').component,
          label: this.$t('common.withdrawChannel.E_WALLET'),
          name: `eWallet`,
        },
        {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/localTransfer/depositList').component,
          label: this.$t('common.withdrawChannel.LOCAL_TRANSFER'),
          name: `localTransfer`,
        },
        {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/bankTransfer/depositList').component,
          label: this.$t('common.withdrawChannel.banktransfer'),
          name: `bankTransfer`,
        },
      ],
      currentWidth: 0,
      cpsDepositList: [],
    }
  },
  computed: {
    getComponent() {
      return this.tabs.find(item => item.name === this.selectedTab).component
    },
  },
  mounted() {
    // get cps deposit channels list
    this.getCPSDepositChannels()
  },
  methods: {
    observerCallBack(width) {
      this.currentWidth = width
    },
  },
}
</script>
